import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

const Page404 = ({ onGoToHome }) => {
	return (
		<section className="page404">
			<img
				src={"https://a.storyblok.com/f/159381/1026x858/91de1c8439/404.png"}
				alt="bloco laranja com número 404"
				className="page404__image"
			/>

			<div className="page404__wrapper">
				<Text className="page404__title" size="xl" weight="900">
					Página não encontrada
				</Text>
				<Text className="page404__description" size="xxl">
					Parece que você está tentando acessar uma página que foi deletada ou
					não existe mais. Você pode voltar para a página anterior clicando no
					botão abaixo.
				</Text>
				<Button
					className="page404__button"
					variant="primary"
					fluid
					onClick={onGoToHome}
				>
					ir para a página inicial
				</Button>
			</div>
		</section>
	)
}

export default Page404
