module.exports = {
  Home: {
    path: "/",
    component: "src/pages/Home/index.js"
  },
  Proposal: {
    component: "src/pages/Proposal/index.js",
    path: "/proposta"
  },
  Skipper: {
    path: `/skipper`,
    component: `src/pages/Skipper/index.js`
  },
  404: {
    path: "/404/",
    component: "src/pages/404/index.js"
  }
}
