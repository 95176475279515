import { navigate } from "gatsby"

import * as Routes from "../../Routes"

const { Home } = Routes

const use404 = () => {
  const onGoToHome = () => {
    navigate(Home.path)
  }

  return {
    onGoToHome
  }
}

export default use404
